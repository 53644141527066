var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "judgement-item",
      on: {
        click: function ($event) {
          _vm.showText = false
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "title" },
        [
          _c(
            "van-swipe-cell",
            {
              scopedSlots: _vm._u(
                [
                  !_vm.isPreview
                    ? {
                        key: "right",
                        fn: function () {
                          return [
                            _c("van-button", {
                              attrs: {
                                square: "",
                                type: "primary",
                                text: "上移",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.upMov(_vm.item)
                                },
                              },
                            }),
                            _c("van-button", {
                              attrs: {
                                square: "",
                                type: "primary",
                                text: "下移",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.downMove(_vm.item)
                                },
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      }
                    : null,
                  !_vm.isPreview
                    ? {
                        key: "left",
                        fn: function () {
                          return [
                            _c("van-button", {
                              attrs: {
                                square: "",
                                type: "danger",
                                text: "删 除",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.clickDel(_vm.item)
                                },
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      }
                    : null,
                ],
                null,
                true
              ),
            },
            [
              _c("div", { staticClass: "title-line" }, [
                _c("div", { staticClass: "text" }, [
                  _c(
                    "span",
                    {
                      staticClass: "case-name",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          _vm.showText = !_vm.showText
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.item.case_attr !== "普通案例" &&
                                _vm.item.case_attr !== null,
                              expression:
                                "item.case_attr !== '普通案例' && item.case_attr !== null",
                            },
                          ],
                          staticClass: "tipsty",
                        },
                        [_vm._v(" " + _vm._s(_vm.item.case_attr) + " ")]
                      ),
                      _vm._v(" " + _vm._s(_vm.item.case_name)),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showText && _vm.item.doc_id,
                        expression: "showText && item.doc_id",
                      },
                    ],
                    staticClass: "show-all",
                    on: {
                      click: function ($event) {
                        return _vm.clickTitle(_vm.item)
                      },
                    },
                  },
                  [_vm._v("显示全文")]
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "extra-info",
          style: { color: _vm.itemClickst ? "#999" : "#333" },
        },
        [
          _vm.item.court_name
            ? _c(
                "span",
                { style: { color: _vm.itemClickst ? "#ccc" : "#999" } },
                [_vm._v(_vm._s(_vm.item.court_name))]
              )
            : _vm._e(),
          _vm.item.case_id
            ? _c(
                "span",
                { style: { color: _vm.itemClickst ? "#ccc" : "#999" } },
                [_vm._v(_vm._s(_vm.item.case_id))]
              )
            : _vm._e(),
          _vm.item.pub_date
            ? _c(
                "span",
                { style: { color: _vm.itemClickst ? "#ccc" : "#999" } },
                [_vm._v(_vm._s(_vm.item.pub_date))]
              )
            : _vm._e(),
          _c("span"),
        ]
      ),
      _c(
        "div",
        { ref: "zkcontentDom", staticClass: "vantabshull" },
        [
          _c(
            "van-tabs",
            {
              attrs: { type: "card" },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            _vm._l(_vm.tabList, function (itb, index) {
              return _c(
                "van-tab",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.tabList.length > 0,
                      expression: "tabList.length > 0",
                    },
                  ],
                  key: index + "card",
                  ref: "elTabPane",
                  refInFor: true,
                  attrs: { title: itb.name, name: itb.name },
                  scopedSlots: _vm._u(
                    [
                      itb.f
                        ? {
                            key: "title",
                            fn: function () {
                              return [
                                _c(
                                  "van-tag",
                                  { attrs: { round: "", type: "primary" } },
                                  [_vm._v(_vm._s(_vm.parseTabName(itb)))]
                                ),
                              ]
                            },
                            proxy: true,
                          }
                        : {
                            key: "title",
                            fn: function () {
                              return [_vm._v(" " + _vm._s(itb.name) + " ")]
                            },
                            proxy: true,
                          },
                    ],
                    null,
                    true
                  ),
                },
                [
                  itb.name !== "引用法条"
                    ? _c(
                        "div",
                        {
                          ref: "tabsA",
                          refInFor: true,
                          staticClass: "tabsA-div",
                          attrs: { id: `tab-${itb.name}` },
                        },
                        _vm._l(itb.value, function (item1, index1) {
                          return _c(
                            "span",
                            {
                              key: index1 + "cardsp",
                              class: itb.name === "引用法条" ? "tabsAspan" : "",
                            },
                            [
                              typeof item1 === "string"
                                ? [
                                    _c("span", {
                                      domProps: { innerHTML: _vm._s(item1) },
                                    }),
                                  ]
                                : _vm._e(),
                              typeof item1 === "object"
                                ? [
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(item1.title),
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  itb.name === "引用法条"
                    ? _c(
                        "div",
                        {
                          ref: "tabsA",
                          refInFor: true,
                          staticClass: "tabsA-div",
                          attrs: { id: `tab-${itb.name}` },
                        },
                        _vm._l(itb.value, function (item1, index1) {
                          return _c(
                            "span",
                            {
                              key: index1 + "cardsp",
                              class: itb.name === "引用法条" ? "tabsAspan" : "",
                            },
                            [_vm._v(" " + _vm._s(item1.full_law) + " ")]
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("div", { ref: "sanjiaoDOM", staticClass: "san-jiao-hull" }, [
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.sanjiaoShow,
              expression: "sanjiaoShow",
            },
          ],
          ref: "sanJiao",
          staticClass: "san-jiao",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.sanJiaoClick.apply(null, arguments)
            },
          },
        }),
        _c("div", { ref: "sanjiaoDOMB", staticClass: "san-jiao-cen" }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }