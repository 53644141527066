<template>
  <div class="judgement-item" @click="showText = false">
    <div class="title">
      <van-swipe-cell>
        <div class="title-line">
          <div class="text">
            <span @click.stop="showText = !showText" class="case-name">
              <span class="tipsty" v-show="item.case_attr !== '普通案例' && item.case_attr !== null">
                {{ item.case_attr }}
              </span>
              {{ item.case_name }}</span
            >
          </div>
          <div class="show-all" v-show="showText && item.doc_id" @click="clickTitle(item)">显示全文</div>
        </div>
        <template #right v-if="!isPreview">
          <van-button square type="primary" text="上移" @click="upMov(item)" />
          <van-button square type="primary" text="下移" @click="downMove(item)" />
        </template>
        <template #left v-if="!isPreview">
          <van-button square type="danger" @click="clickDel(item)" text="删 除" />
        </template>
      </van-swipe-cell>
    </div>
    <div class="extra-info" :style="{ color: itemClickst ? '#999' : '#333' }">
      <span v-if="item.court_name" :style="{ color: itemClickst ? '#ccc' : '#999' }">{{ item.court_name }}</span>
      <span v-if="item.case_id" :style="{ color: itemClickst ? '#ccc' : '#999' }">{{ item.case_id }}</span>
      <span v-if="item.pub_date" :style="{ color: itemClickst ? '#ccc' : '#999' }">{{ item.pub_date }}</span>
      <span></span>
    </div>
    <div class="vantabshull" ref="zkcontentDom">
      <van-tabs type="card" v-model="activeName">
        <van-tab v-for="(itb, index) in tabList" :key="index + 'card'" ref="elTabPane" v-show="tabList.length > 0" :title="itb.name" :name="itb.name">
          <template #title v-if="itb.f">
            <van-tag round type="primary">{{ parseTabName(itb) }}</van-tag>
          </template>
          <template #title v-else>
            {{ itb.name }}
          </template>
          <div class="tabsA-div" :id="`tab-${itb.name}`" ref="tabsA" v-if="itb.name !== '引用法条'">
            <span :class="itb.name === '引用法条' ? 'tabsAspan' : ''" v-for="(item1, index1) in itb.value" :key="index1 + 'cardsp'">
              <template v-if="typeof item1 === 'string'">
                <span v-html="item1"></span>
              </template>
              <template v-if="typeof item1 === 'object'">
                <span v-html="item1.title"></span>
              </template>
            </span>
          </div>
          <div class="tabsA-div" :id="`tab-${itb.name}`" ref="tabsA" v-if="itb.name === '引用法条'">
            <span :class="itb.name === '引用法条' ? 'tabsAspan' : ''" v-for="(item1, index1) in itb.value" :key="index1 + 'cardsp'">
              {{ item1.full_law }}
            </span>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <div ref="sanjiaoDOM" class="san-jiao-hull">
      <div v-show="sanjiaoShow" class="san-jiao" ref="sanJiao" @click.stop="sanJiaoClick"></div>
      <div ref="sanjiaoDOMB" class="san-jiao-cen"></div>
    </div>
  </div>
</template>

<script>
import { isFilledArray, typeOf, deepCopy } from '@/utils/assist.js'
const DEFAULT_HEIGHT = 95
export default {
  name: 'judgement-item',
  props: {
    item: Object,
    selected: Boolean,
    itemindex: Number,
    isPreview: Boolean,
  },
  data() {
    return {
      // data: null,
      showText: false,
      // modulename: '',
      // titleShearstate: '',
      // cardtabs: '结果命中',
      // content: [],
      tabsData: {},
      sanjiaoShow: false,
      itemClickst: false,
      // vantabsIndex: 0,
      // vantabsactive: 0,
      // selectTabs: [],
      tabList: [],
      activeName: '结果命中',
    }
  },
  methods: {
    // toDetail(docid, caseAttr, catlog = '', event) {
    //   if (event.target.className.indexOf('van-tab__text') !== -1) {
    //   } else {
    //     this.$emit('toDetail', { docid, caseAttr, catlog })
    //   }
    // },
    // 解析tab名称 区分num是否有值
    parseTabName(item) {
      const { name, num } = item
      return num ? `${name} (${num})` : name
    },
    // 对数据进行预处理
    prepareItemData(item) {
      const preferActive = '结果命中'
      const { content = [], word_frequency: wordfrequency = [] } = deepCopy(item)
      // 添加 f 作为词频标记
      const wf = wordfrequency.map((w) => ({ ...w, f: true })).filter((w) => isFilledArray(w.value) && w.value[0])
      const list = [...content, ...wf]
      console.log(list, 'list')
      // 如果value是字符串则转为数组
      list.forEach((item) => {
        const { value, values } = item
        const val = value || values
        if (typeOf(val) === 'string') {
          item.value = val ? [val] : null
        } else if (isFilledArray(val)) {
          item.value = val.filter((t) => t)
        } else {
          item.value = null
        }
      })

      this.tabList = list.filter((item) => isFilledArray(item.value))
      console.log(this.tabList, '============tabList')
      if (this.tabList.length > 0) {
        // 判断有没有 **结果命中**，如果有就默认选中结果命中，没有就显示其他的
        const jgmz = this.tabList.find((item) => item.name === preferActive)
        if (jgmz) {
          this.activeName = preferActive
        } else {
          this.activeName = wf.length > 0 ? wf[0].name : this.tabList[0].name
        }
      }
      this.$nextTick(() => {
        this.checkClamp()
      })
    },
    upMov(item) {
      console.log('点击上移', item)
      this.$emit('move', {
        direction: 'up',
        listType: 'judgeList',
        reportType: 'judgeReports',
        item: item,
        index: this.itemindex,
      })
    },
    downMove(item) {
      console.log('点击下移', item)
      this.$emit('move', {
        direction: 'down',
        listType: 'judgeList',
        reportType: 'judgeReports',
        item: item,
        index: this.itemindex,
      })
    },
    clickDel() {
      this.$emit('delItem', {
        listType: 'judgeList',
        reportType: 'judgeReports',
        id: this.item.id,
        showConfirm: true,
        index: this.itemindex,
      })
    },
    clickTitle(item) {
      this.$emit('toDetail', {
        docid: item.doc_id,
        caseAttr: item.case_attr,
        catlog: '',
      })
      this.itemClickst = true
    },
    download(id) {
      this.$emit('download', id)
    },
    save(name, id, title) {
      this.$emit('save', name, id, title)
    },
    /* titbtndown() {
      // console.log('titbtndoem')
      this.$bus.$emit('swipeable', false)
    },
    titbtnup() {
      // console.log('titbtnup')
      this.$bus.$emit('swipeable', true)
    },
   titshearFN(e) {
      // this.titleShearstate = !this.titleShearstate
      // console.log('titleShearstate***', this.titleShearstate)
      // 发送数据
      this.$bus.$emit('titsearET', {
        data: this.tabsData,
        id: this.tabsData.id,
        check: this.titleShearstate,
      })
      // 处理数据
      // console.log('data****', data)
    },
    cleartitleShear() {
      this.titleShearstate = false
    }, */
    copyTxt(value) {
      // console.log('复制', value)
      let text = ''
      if (typeof value === 'string') {
        text = value
      } else {
        value.forEach((item) => {
          text += item
        })
      }
      text = text.replace(/<[^>]*>/g, '')
      const textArea = document.createElement('textarea')
      textArea.value = text
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy') // 执行浏览器复制命令
      textArea.remove()
      // this.$notify('内容已复制')
      this.$notify({
        message: '内容已复制',
        duration: 500,
        background: '#1989fa',
      })
      // console.log('复制完成')
      // args = false
    },
    /*
    getStyle(obj, styleName) {
      if (obj.currentStyle) {
        return obj.currentStyle[styleName]
      } else {
        return getComputedStyle(obj, false)[styleName]
      }
    },

    activeBwidth() {
      setTimeout(() => {
        if (this.$refs.antabsA) {
          // console.log(this.$refs.antabsA.$children[0].$el.offsetWidth)
          // const width = this.$refs.antabsA.$children[0].$el.offsetWidth
          // this.tabsBwidth = 823 - width - 20
          // this.tabsshow = true
        }
      }, 1)
    }, */

    sanJiaoClick() {
      console.log('点击了三角')
      const trannum = this.$refs.sanJiao.style.transform
      if (trannum + '' === 'rotate(180deg) translateZ(0px)') {
        this.sanjiaoshow = false
        this.$refs.sanJiao.style.transform = 'rotate(0deg) translateZ(0)'

        this.$refs.tabsA.forEach((item) => {
          item.classList.remove('sanjiaoj')
        })
      } else {
        this.sanjiaoshow = true
        this.$refs.sanJiao.style.transform = 'rotate(180deg) translateZ(0)'

        this.$refs.tabsA.forEach((item) => {
          item.classList.add('sanjiaoj')
        })
      }
    },
    checkClamp() {
      setTimeout(() => {
        const zkcontentDom = this.$refs.zkcontentDom
        if (!zkcontentDom) return
        const tabId = zkcontentDom.querySelector(`#tab-${this.activeName}`)
        if (tabId && tabId.scrollHeight > DEFAULT_HEIGHT) {
          this.sanjiaoShow = true
        } else {
          this.sanjiaoShow = false
        }
      }, 60)
    },
  },
  created() {
    // this.tabsDataInit()
  },
  watch: {
    item: {
      deep: true,
      immediate: true,
      handler(nv) {
        this.tabsData = nv
        this.prepareItemData(nv)
      },
    },
    activeName(nv) {
      this.$nextTick(() => {
        this.checkClamp()
      })
    },
    /* vantabsIndex(nv) {
      // console.log('计算三角')
      this.sanjiaoSFN()
    },
    titleShearstate(nv) {
      try {
        // 只要执行就会报错 即使是空方法
        this.titshearFN()
      } catch {
        console.log('this.titshearfn 错误')
      }
    }, */
  },
  // mounted() {
  //   this.$nextTick(() => {
  //     setTimeout(() => {
  //       this.sanjiaoSFN()
  //     }, 500)
  //   })
  // },
}
</script>

<style lang="stylus">
.judgement-item
  position relative
  padding-bottom 10px
  & .title .title-line
    display flex
    justify-content space-between
    align-items center
    position relative
    .show-all
      position absolute
      right 0
      top 0
      z-index 9
      background rgba(254,254,254,0.8)
      border-radius 5px
      width 40px
      height 40px
      color #409eff
      text-align center
      font-size 14px
      line-height 20px
    .title
      flex 1
  & .extra-info
    padding 0 10px
    & span
      margin-right 6px
      color #999
      &:after
        content '/'
        margin 0 0 0 6px
        color #999
      &:last-child
        margin-right 0
        &:after
          content none
</style>
<style scoped>
.tipsty {
  background: #ff9c39;
  color: #fff;
  display: inline-block;
  border-radius: 5px;
  padding: 0 5px;
  margin-right: 5px;
}
.case-name {
  display: inline-block;
  width: calc(100vw - 20px);
  height: 42px;
}
/* mark会改变解构 */
::v-deep.tipsty mark {
  color: #fff !important ;
}
.tipsty :nth-child(2) {
  display: none;
}
.tabsA-div {
  max-height: 95px;
  /* min-height: 100px; */
  overflow: hidden;
  margin: 0 16px;
  padding: 5px 5px 0 5px;
  border-left: 1px solid #1890ff;
  border-right: 1px solid #1890ff;
  border-top: 1px solid #1890ff;
}
::v-deep.judgement-item > .vantabshull > .van-tabs--card > .van-tabs__content {
  min-height: auto;
}
::v-deep.judgement-item > .vantabshull > .van-tabs--card > .van-tabs__wrap > .van-tabs__nav > .van-tab {
  /* flex-basis: 20% !important; */
  flex: none;
  border: auto;
  /* border-bottom: 1px solid #1890ff; */
  color: #999;
  border-right: none;
}
::v-deep.judgement-item > .vantabshull > .van-tabs--card > .van-tabs__wrap > .van-tabs__nav > .van-tab--active {
  color: #1890ff;
  border: 1px solid #1890ff;
  border-bottom: 1px solid #fff;
  border-radius: 5px 5px 0 0;
  background: #fff;
}
::v-deep.judgement-item > .vantabshull > .van-tabs--card > .van-tabs__wrap > .van-tabs__nav {
  /* border: 0px solid #1890ff; */
  /* border-bottom: 1px solid #1890ff; */
  border: none;
  flex: 0;
  /* display: -webkit-box; */
  /* border-bottom: 1px solid #1890ff; */
}
::v-deep.judgement-item > .vantabshull > .van-tabs.van-tabs--card > .van-tabs__wrap > .van-tabs__nav.van-tabs__nav--card > .van-tab.van-tab--active {
  flex: none;
  /* -webkit-flex: initial; */
  top: 2px;
}
.san-jiao-hull {
  height: 5px;
  border: 1px solid #1890ff;
  border-top: none;
  /* width: 100%; */
  padding: 5px 5px 0 5px;
  margin: 0 16px;
}
.san-jiao {
  width: 0px;
  background: rgba(190, 215, 239, 0);
  border-bottom: solid 16px #bcd8f2;
  border-left: solid 16px rgba(190, 215, 239, 0);
  margin-right: 10px;
  position: absolute;
  right: 15px;
  bottom: 15px;
}
.sanjiaoj {
  max-height: 190px;
  overflow-y: auto;
}
.title-shear {
  /* position: absolute;
  right: 10px; */
  float: right;
  position: relative;
}
.tabsAspan {
  display: block;
}
.icon-copy {
  width: 15px;
  height: 15px;
  position: absolute;
  background: #fff;
}
.icon-copy-a:last-of-type {
  fill: #333;
}
.icon-copy-b:last-of-type {
  fill: #999;
}
.icon-copy-c:last-of-type {
  fill: #000;
}
.san-jiao-cen {
  width: 100%;
}

.vancheck::v-deep > .van-checkbox__icon > .van-icon {
  width: 20px;
  height: 20px;
}
.vancheck::v-deep {
  overflow: visible;
}
.van-check-num {
  position: absolute;
  top: 0;
  right: 8px;
  text-align: center;
  width: 20px;
  height: 20px;
  overflow: hidden;
}
</style>
