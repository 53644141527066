<template>
  <div class="law-item">
    <div class="title" @click="showText = false">
      <van-swipe-cell>
        <div class="title-line">
          <span class="text case-title" @click.stop="showText = !showText">
            <span class="CanNot" v-if="item.timeliness !== '现行有效' && item.timeliness !== ''">
              {{ item.timeliness }}
            </span>
            {{ item.title }}
          </span>
          <div class="show-all" v-show="showText && item.law_id" @click="toLawDetail(item)">显示全文</div>
        </div>
        <template #right v-if="!isPreview">
          <van-button square type="primary" text="上移" @click="upMov('lawList', 'lawReports')" />
          <van-button square type="primary" text="下移" @click="downMove('lawList', 'lawReports')" />
        </template>
        <template #left v-if="!isPreview">
          <van-button square type="danger" @click="clickDel('lawList', 'lawReports')" text="删 除" />
        </template>
      </van-swipe-cell>
    </div>
    <div class="extra-info">
      <span v-if="item.court_name">{{ item.court_name }}</span>
      <span v-if="item.case_id">{{ item.case_id }}</span>
      <span v-if="item.pub_date">{{ item.pub_date }}</span>
      <span></span>
    </div>
    <div ref="lawcontentDom" class="vantabshull">
      <van-tabs type="card" v-model="activeName">
        <van-tab v-for="(itb, index) in tabList" :key="index + 'card'" v-show="tabList.length > 0" :title="itb.name" :name="itb.name">
          <template #title v-if="itb.f">
            <van-tag round type="primary">{{ parseTabName(itb) }}</van-tag>
          </template>
          <template #title v-else>
            {{ itb.name }}
          </template>
          <div :ref="`tabsA${index}`" class="tabsA-div" :id="`tab-${activeName}`" v-if="isAddition(itb.name)">
            <law-addition :list="itb.value" :name="itb.name" />
          </div>
          <div class="tabsA-div" :ref="`tabsA${index}`" :id="`tab-${activeName}`" v-else-if="itb.name === '常用法条' || itb.name === '相关法律' || itb.name === '引用判例'">
            <div :class="itb.name === '引用法条' ? 'tabsAspan' : ''" v-for="(item1, index1) in itb.value" :key="index1 + 'cardsp'">
              <span v-if="itb.catlog && itb.catlog[index1]" class="tabsA-div-titsp">{{ itb.catlog[index1] }}</span>
              <span>
                {{ item1.title }}
              </span>
              <span style="color: #1f459e"> 被{{ item1.num }}篇判例引用 </span>
            </div>
          </div>
          <div v-else class="tabsA-div" :ref="`tabsA${index}`" :id="`tab-${activeName}`">
            <div :class="itb.name === '引用法条' ? 'tabsAspan' : ''" v-for="(item1, index1) in itb.value" :key="index1 + 'cardsp'">
              <span v-if="itb.catlog && itb.catlog[index1]" class="tabsA-div-titsp">{{ itb.catlog[index1] }}</span>
              <span v-html="item1"></span>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <div ref="sanjiaoDOM" class="san-jiao-hull">
      <div v-show="sanjiaoShow" class="san-jiao" ref="sanJiao" @click.stop="sanJiaoClick"></div>
      <div ref="sanjiaoDOMB" class="san-jiao-cen"></div>
    </div>
  </div>
</template>

<script>
import lawAddition from '@/components/fb-bigdata/law-addition'
import { isFilledArray, typeOf, deepCopy } from '@/utils/assist.js'
const DEFAULT_HEIGHT = 95
export default {
  name: 'law-item',
  components: { lawAddition },
  props: {
    item: Object,
    itemindex: Number,
    contentType: String,
    isPreview: Boolean,
  },
  data() {
    return {
      data: null,
      showText: false,
      modulename: '',
      titleShearstate: '',
      cardtabs: '结果命中',
      activeName: '结果命中',
      content: [],
      tabsData: {},
      sanjiaoShow: false,
      vantabsIndex: 0,
      selectTabs: [],
      tabList: [],
    }
  },
  methods: {
    // 关联法条类型
    isAddition(name) {
      return name === '关联法条' || name === '历史沿革'
    },
    upMov(listType, reportType) {
      this.$emit('move', {
        direction: 'up',
        listType,
        reportType,
        item: this.item,
        index: this.itemindex,
      })
    },
    downMove(listType, reportType) {
      this.$emit('move', {
        direction: 'down',
        listType,
        reportType,
        item: this.item,
        index: this.itemindex,
      })
    },
    clickDel(listType, reportType) {
      this.$emit('delItem', {
        listType,
        reportType,
        id: this.item.id,
        showConfirm: true,
        index: this.itemindex,
      })
    },
    toLawDetail(item) {
      this.$emit('toDetail', item)
    },
    save(name, id, title) {
      this.$emit('save', name, id, title)
    },
    titbtndown() {
      // console.log('titbtndoem')
      this.$bus.$emit('swipeable', false)
    },
    titbtnup() {
      // console.log('titbtnup')
      this.$bus.$emit('swipeable', true)
    },
    titshearFN(e) {
      // 发送数据
      this.$bus.$emit('titsearET', {
        data: this.tabsData,
        id: this.tabsData.id,
        check: this.titleShearstate,
      })
      // 处理数据
      // console.log('data****', data)
    },
    cleartitleShear() {
      this.titleShearstate = false
    },
    copyTxt(value) {
      // console.log('复制', value)
      let text = ''
      if (typeof value === 'string') {
        text = value
      } else {
        value.forEach((item) => {
          text += item
        })
      }
      text = text.replace(/<[^>]*>/g, '')
      const textArea = document.createElement('textarea')
      textArea.value = text
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy') // 执行浏览器复制命令
      textArea.remove()
      // this.$notify('内容已复制')
      this.$notify({
        message: '内容已复制',
        duration: 500,
        background: '#1989fa',
      })
      // console.log('复制完成')
      // args = false
    },
    getStyle(obj, styleName) {
      if (obj.currentStyle) {
        return obj.currentStyle[styleName]
      } else {
        return getComputedStyle(obj, false)[styleName]
      }
    },
    /* sanjiaoSFN(num = 0, index = 0) {
      console.log('sanjiaosfn length')
      var that = this
      num += 1
      this.$nextTick(() => {
        if (that.$refs[`tabsA${index}`]) {
          const hei = that.$refs[`tabsA${index}`][0].offsetHeight
          console.log('hei****', hei)
          if (hei > 94) {
            that.sanjiaoShow = true
          } else {
            that.sanjiaoShow = false
          }
        } else if (num < 10) {
          that.sanjiaoSFN(num)
        }
      })
    }, */
    activeBwidth() {
      setTimeout(() => {
        if (this.$refs.antabsA) {
          // console.log(this.$refs.antabsA.$children[0].$el.offsetWidth)
          // const width = this.$refs.antabsA.$children[0].$el.offsetWidth
          // this.tabsBwidth = 823 - width - 20
          // this.tabsshow = true
        }
      }, 1)
    },
    /* tabsDataInit() {
      // this.tabsData = []
      // console.table('tabsdatainit', this.item)
      this.tabsData = JSON.parse(JSON.stringify(this.item))
      console.log('this.tabsdata', this.tabsData)
      this.selectTabs = []
      if (this.tabsData.word_frequency) {
        this.tabsData.word_frequency.forEach(item => {
          if (item.name === '结果命中') {
            item.init = true
            this.tabsData.content.push(item)
          } else {
            this.selectTabs.push(item)
          }
        })
      } else {
        // console.log('啊啊', this.tabsData)
      }
      if (this.tabsData.content && this.tabsData.content.length) {
        for (let i = 0; i < this.tabsData.content.length; i++) {
          let value = this.tabsData.content[i].value
          if (value.length === 0) {
            if (this.tabsData.content[i].value.length === 0) {
              this.tabsData.content.splice(i, 1)
              i -= 1
            } else if (value.length === 1 && value[0] === '') {
              this.tabsData.content.splice(i, 1)
              i -= 1
            }
          }
          this.content = this.tabsData.content
          // console.table('数据处理完成aaaa', this.tabsData)
          // this.activeBwidth()
          // this.$set(this.tabsData, 0, this.tabsData[0])
          this.vantabsIndex = this.content.length - 1
        }
      }
      this.content.forEach(v => {
        if (v.name === '关联法条') {
          console.log(v)
        }
      })
    }, */
    sanJiaoClick() {
      console.log('点击了三角2')
      const trannum = this.$refs.sanJiao.style.transform
      console.log(this.$refs.sanJiao.style, this.$refs.sanJiao.style.cssText, trannum)
      if (trannum + '' === 'rotate(180deg) translateZ(0px)') {
        this.sanjiaoshow = false
        this.$refs.sanJiao.style.transform = 'rotate(0deg) translateZ(0)'
        this.tabList.forEach((item, index) => {
          if (this.$refs[`tabsA${index}`]) {
            this.$refs[`tabsA${index}`][0].classList.remove('sanjiaoj')
          }
        })
        // this.$refs.tabsA.forEach(item => {
        //   item.classList.remove('sanjiaoj')
        // })
      } else {
        this.sanjiaoshow = true
        this.$refs.sanJiao.style.transform = 'rotate(180deg) translateZ(0)'
        this.tabList.forEach((item, index) => {
          if (this.$refs[`tabsA${index}`]) {
            this.$refs[`tabsA${index}`][0].classList.add('sanjiaoj')
          }
        })
        // this.$refs.tabsA.forEach(item => {
        //   item.classList.add('sanjiaoj')
        // })
      }
    },
    // 解析tab名称 区分num是否有值
    parseTabName(item) {
      const { name, num } = item
      return num ? `${name} (${num})` : name
    },
    // 对数据进行预处理
    prepareItemData(item) {
      console.log('item变化', item)

      const preferActive = '结果命中'
      const { content = [], word_frequency: wordfrequency = [] } = deepCopy(item)
      // 添加 f 作为词频标记
      const wf = wordfrequency.map((w) => ({ ...w, f: true })).filter((w) => isFilledArray(w.value) && w.value[0])
      const list = [...content, ...wf]

      // 如果value是字符串则转为数组
      list.forEach((item) => {
        const { value } = item
        if (typeOf(value) === 'string') {
          item.value = value ? [value] : null
        } else if (isFilledArray(value)) {
          item.value = value.filter((t) => t)
        } else {
          item.value = null
        }
      })

      this.tabList = list.filter((item) => isFilledArray(item.value))

      if (this.tabList.length > 0) {
        // 判断有没有 **结果命中**，如果有就默认选中结果命中，没有就显示其他的
        const jgmz = this.tabList.find((item) => item.name === preferActive)
        if (jgmz) {
          this.activeName = preferActive
        } else {
          this.activeName = wf.length > 0 ? wf[0].name : this.tabList[0].name
        }
      }
      this.$nextTick(() => {
        this.checkClamp()
      })
    },
    checkClamp() {
      setTimeout(() => {
        const lawcontentDom = this.$refs.lawcontentDom
        if (!lawcontentDom) return
        const tabId = lawcontentDom.querySelector(`#tab-${this.activeName}`)
        if (tabId && tabId.scrollHeight > DEFAULT_HEIGHT) {
          this.sanjiaoShow = true
        } else {
          this.sanjiaoShow = false
        }
      }, 60)
    },
  },
  created() {
    console.log('created created')
    // this.tabsDataInit()
  },
  watch: {
    item: {
      deep: true,
      immediate: true,
      handler(nv) {
        this.tabsData = nv
        this.prepareItemData(nv)
        // this.tabsDataInit()
        // this.sanjiaoSFN()
      },
    },
    activeName(nv) {
      this.$nextTick(() => {
        this.checkClamp()
      })
    },
    // vantabsIndex(nv) {
    //   // console.log('计算三角')
    //   this.sanjiaoSFN(0, nv)
    // },
    titleShearstate(nv) {
      try {
        // 只要执行就会报错 即使是空方法
        this.titshearFN()
      } catch {
        console.log('this.titshearfn 错误')
      }
    },
  },
  mounted() {
    // this.$nextTick(() => {
    //   setTimeout(() => {
    //     this.sanjiaoSFN()
    //   }, 500)
    // })
  },
}
</script>
<style lang="stylus" scoped>
.item
  position relative
  & .title
    width 100%
    padding 10px
    overflow hidden
    cursor pointer
    display flex
    flex-direction row
    .title-line
      display flex
      justify-content space-between
      align-items center
      position relative
    .show-all
      position absolute
      right 0
      top 0
      z-index 9
      background rgba(254,254,254,0.8)
      border-radius 5px
      width 40px
      height 40px
      color #409eff
      text-align center
      font-size 14px
      line-height 20px
    .case-title
      flex 1
      min-height 42px
      display inline-block
      width calc(100vw - 20px)
    & .CanNot
      height 21px
      display: inline-block;
      box-sizing border-box
      background #ff9c39
      color #fff
      line-height 21px
      padding 0 5px
      border-radius 5px
  & .showrow
    width 100%
    padding 10px
    padding-top 0
    overflow hidden
    & .text
      font-size 12px
      color #999999
      font-weight normal
      float left
    & .open
      float left
      font-size 12px
      color #999999
      font-weight normal
      overflow hidden
      margin-right 10px
      cursor pointer
      & i
        font-size 14px
      & .tip
        overflow hidden
        padding 3px 5px 3px
        background-color #ff9c39
        font-size 14px
        color white
        border-radius 3px
  & .zkcontent
    width 100%
    padding 0 10px
    margin-bottom 10px
    & h4
      font-size 14px
    & span
      font-size 12px
</style>
<style lang="stylus">
.law-item
  position relative
  padding-bottom 10px
  & .extra-info
    padding 0 10px
    & span
      margin-right 6px
      color #999
      &:after
        content '/'
        margin 0 0 0 6px
        color #999
      &:last-child
        margin-right 0
        &:after
          content none
</style>
<style scoped>
.tipsty {
  background: #ff9c39;
  color: #fff;
  display: inline-block;
  border-radius: 5px;
  padding: 0 5px;
}
.case-title {
  display: inline-block;
  height: 42px;
}
/* mark会改变解构 */
::v-deep.tipsty mark {
  color: #fff !important ;
}
.tipsty :nth-child(2) {
  display: none;
}
.tabsA-div {
  max-height: 95px;
  overflow: hidden;
  margin: 0 16px;
  padding: 5px 5px 0 5px;
  border-left: 1px solid #1890ff;
  border-right: 1px solid #1890ff;
  border-top: 1px solid #1890ff;
}
.tabsA-div .guanlian {
  list-style-type: circle;
}
.tabsA-div .guanlian .guanlian-item {
  display: flex;
  line-height: 22px;
  margin-bottom: 2px;
  list-style-type: circle;
}
.tabsA-div .guanlian .guanlian-item > span {
  padding: 2px 3px;
  color: red;
  background: pink;
}
::v-deep.law-item > .vantabshull > .van-tabs--card > .van-tabs__content {
  min-height: auto;
}
::v-deep.law-item > .vantabshull > .van-tabs--card > .van-tabs__wrap > .van-tabs__nav > .van-tab {
  /* flex-basis: 20% !important; */
  flex: none;
  border: auto;
  /* border-bottom: 1px solid #1890ff; */
  color: #999;
  border-right: none;
}
::v-deep.law-item > .vantabshull > .van-tabs--card > .van-tabs__wrap > .van-tabs__nav > .van-tab--active {
  color: #1890ff;
  border: 1px solid #1890ff;
  border-bottom: 1px solid #fff;
  border-radius: 5px 5px 0 0;
  background: #fff;
}
::v-deep.law-item > .vantabshull > .van-tabs--card > .van-tabs__wrap > .van-tabs__nav {
  /* border: 0px solid #1890ff; */
  /* border-bottom: 1px solid #1890ff; */
  border: none;
  flex: 0;
  /* display: -webkit-box; */
  /* border-bottom: 1px solid #1890ff; */
}
::v-deep.law-item > .vantabshull > .van-tabs.van-tabs--card > .van-tabs__wrap > .van-tabs__nav.van-tabs__nav--card > .van-tab.van-tab--active {
  flex: none;
  /* -webkit-flex: initial; */
  top: 2px;
}
.san-jiao-hull {
  height: 5px;
  border: 1px solid #1890ff;
  border-top: none;
  /* width: 100%; */
  padding: 5px 5px 0 5px;
  margin: 0 16px;
}
.san-jiao {
  width: 0px;
  background: rgba(190, 215, 239, 0);
  border-bottom: solid 16px #bcd8f2;
  border-left: solid 16px rgba(190, 215, 239, 0);
  margin-right: 10px;
  position: absolute;
  right: 15px;
  bottom: 15px;
}
.sanjiaoj {
  max-height: 190px;
  overflow-y: auto;
}
.title-shear {
  /* position: absolute;
  right: 10px; */
  float: right;
  position: relative;
}
.tabsAspan {
  display: block;
}
.icon-copy {
  width: 15px;
  height: 15px;
  position: absolute;
  background: #fff;
}
.icon-copy-a:last-of-type {
  fill: #333;
}
.icon-copy-b:last-of-type {
  fill: #999;
}
.icon-copy-c:last-of-type {
  fill: #000;
}
.san-jiao-cen {
  width: 100%;
}

.vancheck::v-deep > .van-checkbox__icon > .van-icon {
  width: 20px;
  height: 20px;
}
.vancheck::v-deep {
  overflow: visible;
}
.van-check-num {
  position: absolute;
  top: 0;
  right: 8px;
  text-align: center;
  width: 20px;
  height: 20px;
  overflow: hidden;
}
</style>
