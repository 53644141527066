var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "law-item" }, [
    _c(
      "div",
      {
        staticClass: "title",
        on: {
          click: function ($event) {
            _vm.showText = false
          },
        },
      },
      [
        _c(
          "van-swipe-cell",
          {
            scopedSlots: _vm._u(
              [
                !_vm.isPreview
                  ? {
                      key: "right",
                      fn: function () {
                        return [
                          _c("van-button", {
                            attrs: {
                              square: "",
                              type: "primary",
                              text: "上移",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.upMov("lawList", "lawReports")
                              },
                            },
                          }),
                          _c("van-button", {
                            attrs: {
                              square: "",
                              type: "primary",
                              text: "下移",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.downMove("lawList", "lawReports")
                              },
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
                !_vm.isPreview
                  ? {
                      key: "left",
                      fn: function () {
                        return [
                          _c("van-button", {
                            attrs: {
                              square: "",
                              type: "danger",
                              text: "删 除",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.clickDel("lawList", "lawReports")
                              },
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
              ],
              null,
              true
            ),
          },
          [
            _c("div", { staticClass: "title-line" }, [
              _c(
                "span",
                {
                  staticClass: "text case-title",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.showText = !_vm.showText
                    },
                  },
                },
                [
                  _vm.item.timeliness !== "现行有效" &&
                  _vm.item.timeliness !== ""
                    ? _c("span", { staticClass: "CanNot" }, [
                        _vm._v(" " + _vm._s(_vm.item.timeliness) + " "),
                      ])
                    : _vm._e(),
                  _vm._v(" " + _vm._s(_vm.item.title) + " "),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showText && _vm.item.law_id,
                      expression: "showText && item.law_id",
                    },
                  ],
                  staticClass: "show-all",
                  on: {
                    click: function ($event) {
                      return _vm.toLawDetail(_vm.item)
                    },
                  },
                },
                [_vm._v("显示全文")]
              ),
            ]),
          ]
        ),
      ],
      1
    ),
    _c("div", { staticClass: "extra-info" }, [
      _vm.item.court_name
        ? _c("span", [_vm._v(_vm._s(_vm.item.court_name))])
        : _vm._e(),
      _vm.item.case_id
        ? _c("span", [_vm._v(_vm._s(_vm.item.case_id))])
        : _vm._e(),
      _vm.item.pub_date
        ? _c("span", [_vm._v(_vm._s(_vm.item.pub_date))])
        : _vm._e(),
      _c("span"),
    ]),
    _c(
      "div",
      { ref: "lawcontentDom", staticClass: "vantabshull" },
      [
        _c(
          "van-tabs",
          {
            attrs: { type: "card" },
            model: {
              value: _vm.activeName,
              callback: function ($$v) {
                _vm.activeName = $$v
              },
              expression: "activeName",
            },
          },
          _vm._l(_vm.tabList, function (itb, index) {
            return _c(
              "van-tab",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.tabList.length > 0,
                    expression: "tabList.length > 0",
                  },
                ],
                key: index + "card",
                attrs: { title: itb.name, name: itb.name },
                scopedSlots: _vm._u(
                  [
                    itb.f
                      ? {
                          key: "title",
                          fn: function () {
                            return [
                              _c(
                                "van-tag",
                                { attrs: { round: "", type: "primary" } },
                                [_vm._v(_vm._s(_vm.parseTabName(itb)))]
                              ),
                            ]
                          },
                          proxy: true,
                        }
                      : {
                          key: "title",
                          fn: function () {
                            return [_vm._v(" " + _vm._s(itb.name) + " ")]
                          },
                          proxy: true,
                        },
                  ],
                  null,
                  true
                ),
              },
              [
                _vm.isAddition(itb.name)
                  ? _c(
                      "div",
                      {
                        ref: `tabsA${index}`,
                        refInFor: true,
                        staticClass: "tabsA-div",
                        attrs: { id: `tab-${_vm.activeName}` },
                      },
                      [
                        _c("law-addition", {
                          attrs: { list: itb.value, name: itb.name },
                        }),
                      ],
                      1
                    )
                  : itb.name === "常用法条" ||
                    itb.name === "相关法律" ||
                    itb.name === "引用判例"
                  ? _c(
                      "div",
                      {
                        ref: `tabsA${index}`,
                        refInFor: true,
                        staticClass: "tabsA-div",
                        attrs: { id: `tab-${_vm.activeName}` },
                      },
                      _vm._l(itb.value, function (item1, index1) {
                        return _c(
                          "div",
                          {
                            key: index1 + "cardsp",
                            class: itb.name === "引用法条" ? "tabsAspan" : "",
                          },
                          [
                            itb.catlog && itb.catlog[index1]
                              ? _c("span", { staticClass: "tabsA-div-titsp" }, [
                                  _vm._v(_vm._s(itb.catlog[index1])),
                                ])
                              : _vm._e(),
                            _c("span", [
                              _vm._v(" " + _vm._s(item1.title) + " "),
                            ]),
                            _c("span", { staticStyle: { color: "#1f459e" } }, [
                              _vm._v(" 被" + _vm._s(item1.num) + "篇判例引用 "),
                            ]),
                          ]
                        )
                      }),
                      0
                    )
                  : _c(
                      "div",
                      {
                        ref: `tabsA${index}`,
                        refInFor: true,
                        staticClass: "tabsA-div",
                        attrs: { id: `tab-${_vm.activeName}` },
                      },
                      _vm._l(itb.value, function (item1, index1) {
                        return _c(
                          "div",
                          {
                            key: index1 + "cardsp",
                            class: itb.name === "引用法条" ? "tabsAspan" : "",
                          },
                          [
                            itb.catlog && itb.catlog[index1]
                              ? _c("span", { staticClass: "tabsA-div-titsp" }, [
                                  _vm._v(_vm._s(itb.catlog[index1])),
                                ])
                              : _vm._e(),
                            _c("span", {
                              domProps: { innerHTML: _vm._s(item1) },
                            }),
                          ]
                        )
                      }),
                      0
                    ),
              ]
            )
          }),
          1
        ),
      ],
      1
    ),
    _c("div", { ref: "sanjiaoDOM", staticClass: "san-jiao-hull" }, [
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.sanjiaoShow,
            expression: "sanjiaoShow",
          },
        ],
        ref: "sanJiao",
        staticClass: "san-jiao",
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.sanJiaoClick.apply(null, arguments)
          },
        },
      }),
      _c("div", { ref: "sanjiaoDOMB", staticClass: "san-jiao-cen" }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }